
import axios from 'axios';
import { Toast, Dialog } from 'vant';
// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符， 
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字) 
// 例子： 
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423 
// (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18 
Date.prototype.format = function (fmt) { //author: meizz 
	var o = {
		"M+": this.getMonth() + 1, //月份 
		"d+": this.getDate(), //日 
		"h+": this.getHours(), //小时 
		"m+": this.getMinutes(), //分 
		"s+": this.getSeconds(), //秒 
		"q+": Math.floor((this.getMonth() + 3) / 3), //季度 
		"S": this.getMilliseconds() //毫秒 
	};
	if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
	for (var k in o)
		if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : ((
			"00" + o[k]).substr(("" + o[k]).length)));
	return fmt;
}

Date.prototype.DateAdd = function (strInterval, Number) {
	var dtTmp = this;
	switch (strInterval) {
		case 's': return new Date(Date.parse(dtTmp) + (1000 * Number));
		case 'n': return new Date(Date.parse(dtTmp) + (60000 * Number));
		case 'h': return new Date(Date.parse(dtTmp) + (3600000 * Number));
		case 'd': return new Date(Date.parse(dtTmp) + (86400000 * Number));
		case 'w': return new Date(Date.parse(dtTmp) + ((86400000 * 7) * Number));
		case 'q': return new Date(dtTmp.getFullYear(), (dtTmp.getMonth()) + Number * 3, dtTmp.getDate(), dtTmp.getHours(), dtTmp.getMinutes(), dtTmp.getSeconds());
		case 'm': return new Date(dtTmp.getFullYear(), (dtTmp.getMonth()) + Number, dtTmp.getDate(), dtTmp.getHours(), dtTmp.getMinutes(), dtTmp.getSeconds());
		case 'y': return new Date((dtTmp.getFullYear() + Number), dtTmp.getMonth(), dtTmp.getDate(), dtTmp.getHours(), dtTmp.getMinutes(), dtTmp.getSeconds());
	}
}

function Http() {
	this.post = function (url, data) {
		return new Promise((res, rej) => {
			axios.post(url, Object.assign({ token: localStorage.getItem("hanbio_app_token") }, data)).then(re => {
				if (re.data.code == 0) {
					res(re.data);
				} else {
					Toast.fail(re.data.msg || "请求失败");
				}
			}).catch(ex => {
				console.log(ex);
			});
		})
	}
}

function Utils() {
	this.baseurl ="https://oa123.hanbio.net";// "http://192.168.0.103:48486"// "http://192.168.2.4:48486"//

	this.htmlDecodeByRegExp = function (str) {
		var s = "";
		if (str.length == 0 || typeof str === "number") {
			return str;
		}
		s = str.replace(/&/g, "&");
		s = str.replace(/&amp;/g, "&");
		s = s.replace(/</g, "<");
		s = s.replace(/>/g, ">");
		s = s.replace(/ /g, " ");
		s = s.replace(/’/g, "’");
		s = s.replace(/"""/g, "\"");
		return s;
	};
	this.GetUrlParam = function (paraName) {
		var url = document.location.toString();
		var arrObj = url.split("?");
		if (arrObj.length > 1) {
			var arrPara = arrObj[1].split("&");
			var arr;
			for (var i = 0; i < arrPara.length; i++) {
				arr = arrPara[i].split("=");
				if (arr != null && arr[0] == paraName) {
					return decodeURIComponent(arr[1]);
				}
			}
			return "";
		} else {
			return "";
		}
	};

	this.downImg = function (imgsrc) {
		const src = `${imgsrc}?t=${new Date().getTime()}`
		fetch(src).then(res => {
			res.blob().then(myBlob => {
				const href = URL.createObjectURL(myBlob)
				const a = document.createElement('a')
				a.href = href;
				a.download = new Date().getTime() + '.jpg'
				a.click();
				a.remove();
			})
		})
	};

	this.fixImageUrl = function (url) {
		if (url) {
			if (url.indexOf("~") == 0) url = url.substring(1, url.length);
			return (url.toLowerCase().indexOf("http") != 0) ? this.baseurl + url : url;
		}
		return url;
	}
	this.getshorttime = function (t) {
		if (t != null)
			return t.substring(0, 10);
		else
			return t;
	};
	this.base64File = function (arr) {
		var obj = arr.filter(x => x.file && x.content).map(x => {
			return { name: x.file.name, ct: x.content }
		})
		return JSON.stringify(obj);
	};
	this.getDropdown = async function (token, category, code) {
		return new Promise((rej, res) => {
			axios.post("?action=App_GetDropdown", { token: token, classify: category, code: code }).then(re => {
				if (re.data.code == 0) {
					rej(re.data.obj);
				} else {
					Toast(re.data.msg || "获取数据失败");
				}
			})
		})
	};
}

function fixImageUrl(url) {
	console.log(this.baseurl);
	return url && url.toLowerCase().indexOf("http") != 0 ? Utils.baseurl + url : url;
}

const utils = new Utils();
const http = new Http();
export {
	utils, fixImageUrl, http
}
